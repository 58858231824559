export const navLinksdata =[
    {
        _id: 1001,
        title: "Home",
        link: "home",
      },
      {
        _id: 1002,
        title: "About",
        link: "about",
      },
      {
        _id: 1003,
        title: "Projects",
        link: "projects",
      },
      {
        _id: 1004,
        title: "Skills",
        link: "skills",
      },
      {
        _id: 1005,
        title: "Experience",
        link: "experience",

      },
      {
        _id: 1006,
        title: "Contact",
        link: "contact",
      },
];